import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "uppercase bg-theme-gray-7 text-theme-gray-2 text-size-1 col-span-8 p-5"
}
const _hoisted_2 = {
  key: 4,
  class: "flex items-center justify-center col-span-7 md:col-span-1 py-4 md:py-0"
}

import { ref, onMounted, watch } from 'vue'
import { LocationSchedule, DriveType, UserSearch, GoogleLocationType, Locality } from '@/types/index'
import router from '@/router'
import NavTabs from '@/components/search/NavTabs.vue'
import DateField from '@/components/search/DateField.vue'
import TimeField from '@/components/search/TimeField.vue'
import LocationField from '@/components/search/LocationField.vue'
import DaysField from '@/components/search/DaysField.vue'
import TransferTypeField from '@/components/search/TransferTypeField.vue'
import ExtraTimeField from '@/components/search/ExtraTimeField.vue'
import { resetErrors, getHoursPerDay, getDiffInHours } from '@/utils/common'
import { encodeIntoQuery, saveUserSearch } from '@/services/search-service'
import { parseQuery } from 'vue-router'
import LocationService from '@/services/location-service'
import ConfirmationModal from '@/widgets/ConfirmationModal.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'Search',
  props: ['layout', 'type', 'data', 'proceed'],
  emits: ['pickUp', 'dropOff', 'proceedInitiated'],
  setup(__props, { emit: __emit }) {

const props = __props
const emit = __emit
const drive = ref<DriveType>(props.type)
const { getCoordinates }  = LocationService()
const chaufferHours = ref<number | null>(getHoursPerDay())
const countryFilter = ref<string | null>(null)
const placeId = ref<string | null>(null)
const openConfirmModal = ref<boolean>(false)
const extraTime = ref<number | null>(null)
const lastMinuteBookText = ref<string>('System accepts orders not less than 24 hours. For last minute orders please use multiple requests and will provide you with the confirmation even few hours in advance.')
const pickUp = ref<LocationSchedule>({
   location: {
      id: '',
      name: '',
      isAirport: false,
      coordinates: {
         lat: null,
         lng: null
      }
   },
   date: null,
   time: ''
})
const dropOff = ref<LocationSchedule>({
   location: {
      id: '',
      name: '',
      isAirport: false,
      coordinates: {
         lat: null,
         lng: null
      }
   }
})

const errors = ref({
   pickUp: {
      location: false,
      date: false,
      time: false
   },
   dropOff: {
      location: false
   }
})

const proceedInitBooking = async () => {

   await setAddressCoordinates()
   resetErrors(errors.value)
   let isValid = validateSearchForm()

   if (props.layout == 'simple') {
      if (isValid) {
         if (pickUp.value && pickUp.value.date) {
            const date = `${pickUp.value.date} ${pickUp.value.time}`
            const hours = getDiffInHours(date)
            if (hours < 24) {
               openConfirmModal.value = true
            } else{
               const userSearch : UserSearch = {
                  type: drive.value,
                  mode: 'one-way',
                  pickUp: pickUp.value,
                  dropOff: dropOff.value,
                  hours: chaufferHours.value as number,
                  extraTime: extraTime.value as number
               }
               saveUserSearch(userSearch)

               router.push({name: 'BookingWizard', params: { step: 'choose-vehicle' }})
               //router.push({name: 'BookingWizard', params: { step: 'ride-details' }, query: parseQuery(encodeIntoQuery(drive.value, pickUp.value, dropOff.value, chaufferHours.value))})
            }
         }
      }
   } else {
      if (isValid) {

         const userSearch : UserSearch = {
            type: drive.value,
            mode: 'one-way',
            pickUp: pickUp.value,
            dropOff: dropOff.value,
            hours: chaufferHours.value as number,
            extraTime: extraTime.value as number
         }
         saveUserSearch(userSearch)

         router.push({name: 'BookingWizard', params: { step: 'choose-vehicle' }})
      }
   }
}

const handleDriveTypeChange = (value: string) => {
   drive.value = value as DriveType
}

const handleAddressSelected = (address: GoogleLocationType | null, type: string) => {

   let add
   if (address != null) {
      add = address
   } else {
      add = {id: '', name: '', coordinates: {lat: null, lng: null}}
   }

   if (type == 'pickUp') {
      const updatedPickUp = { ...pickUp.value, location: add }
      pickUp.value = updatedPickUp
      countryFilter.value = address?.country as string
      placeId.value = address?.id as string
   } else if (type == 'dropOff') {
      const updatedDropOff = { ...dropOff.value, location: add }
      dropOff.value = updatedDropOff
   }
}

const handleDateChanged = (date: string) => {
   pickUp.value.date = date
}

const handleExtraTimeChanged = (value: number) => {
   extraTime.value = value
}

const handleConfirmClose = () => openConfirmModal.value = false

const handleDaysChanged = (value: number) => {
   chaufferHours.value = value
}

const handleTimeChanged = (time: string) => {
   pickUp.value.time = time
}

const askingConfirmed = async () => {
   router.push({name: 'Planmytrip'})  
}

const validateSearchForm = () : boolean => {

   let response = true
   
   if (pickUp.value.date === null) {
      errors.value.pickUp.date = true
      response = false
   }
   
   if (pickUp.value.time == '') {
      errors.value.pickUp.time = true
      response =  false
   }
   
   if (pickUp.value.location.id === '' || pickUp.value.location.coordinates.lat === null || pickUp.value.location.coordinates.lng === null) {
      errors.value.pickUp.location = true
      response =  false
   }
   
   if (drive.value == 'distance' && dropOff.value.location.id === '') {
      errors.value.dropOff.location = true
      response =  false
   }

   return response 
}

const setAddressCoordinates = async () : Promise<void> => {

   let response
   if (pickUp.value && pickUp.value.location.id !== '') {
      response = await getCoordinates(pickUp.value.location.id)

      if (response.success) {
         const updatedPickUp = { ...pickUp.value, location: { ...pickUp.value.location, coordinates: { ...response.data } } }
         pickUp.value = updatedPickUp
      }
   }

   if (drive.value == 'distance' && dropOff.value && dropOff.value.location.id !== '') {
      response = await getCoordinates(dropOff.value.location.id)

      if (response.success) {
         const updatedDropOff = { ...dropOff.value, location: { ...dropOff.value.location, coordinates: { ...response.data } } }
         dropOff.value = updatedDropOff
      }
   }
}

watch(() => pickUp.value, (newValue, oldValue) => {
   emit('pickUp', newValue)
})

watch(() => dropOff.value, (newValue, oldValue) => {
   emit('dropOff', newValue)
})

watch(() => props.proceed, (newVallue, oldValue) => {
   
   if (newVallue) {
      proceedInitBooking()
      emit('proceedInitiated', '1')
   }
})

const mounted = () => {

}

onMounted(() => {
   mounted()
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["flex flex-col", __props.layout == 'simple' ? 'md:space-y-3' : ''])
    }, [
      _createVNode(NavTabs, {
        layout: __props.layout,
        driveType: drive.value,
        onDriveType: handleDriveTypeChange
      }, null, 8, ["layout", "driveType"]),
      _createElementVNode("div", {
        class: _normalizeClass(["grid divide-x divide-y divide-theme-gray-7 gap-0 w-full bg-white", __props.layout == 'simple' ? 'grid-cols-7' : 'grid-cols-8 border-r border-b border-l border-theme-gray-7'])
      }, [
        (__props.layout == 'full')
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, "Ride Details"))
          : _createCommentVNode("", true),
        _createVNode(DateField, {
          layout: __props.layout,
          hasError: errors.value.pickUp.date,
          data: __props.data && __props.data.pickUp ? __props.data.pickUp.date : null,
          onDateChanged: handleDateChanged
        }, null, 8, ["layout", "hasError", "data"]),
        _createVNode(TimeField, {
          layout: __props.layout,
          hasError: errors.value.pickUp.time,
          data: __props.data && __props.data.pickUp ? __props.data.pickUp.time : null,
          onTimeChanged: handleTimeChanged
        }, null, 8, ["layout", "hasError", "data"]),
        _createVNode(LocationField, {
          layout: __props.layout,
          drive: drive.value,
          hasError: errors.value.pickUp.location,
          data: __props.data && __props.data.pickUp ? __props.data.pickUp : null,
          type: "pickUp",
          label: "Pick up location",
          onAddressSelected: handleAddressSelected
        }, null, 8, ["layout", "drive", "hasError", "data"]),
        (drive.value == 'distance')
          ? (_openBlock(), _createBlock(LocationField, {
              key: 1,
              drive: drive.value,
              hasError: errors.value.dropOff.location,
              layout: __props.layout,
              data: __props.data && __props.data.dropOff ? __props.data.dropOff : null,
              countryFilter: countryFilter.value,
              placeId: placeId.value,
              type: "dropOff",
              label: "Drop-off location",
              onAddressSelected: handleAddressSelected
            }, null, 8, ["drive", "hasError", "layout", "data", "countryFilter", "placeId"]))
          : (drive.value == 'hourly')
            ? (_openBlock(), _createBlock(DaysField, {
                key: 2,
                layout: __props.layout,
                data: __props.data ? __props.data.hours : null,
                onSelected: handleDaysChanged
              }, null, 8, ["layout", "data"]))
            : _createCommentVNode("", true),
        (__props.layout == 'full' && drive.value == 'distance')
          ? (_openBlock(), _createBlock(TransferTypeField, {
              key: 3,
              layout: __props.layout
            }, null, 8, ["layout"]))
          : _createCommentVNode("", true),
        (__props.layout == 'simple')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", { class: "flex flex-col space-y-2" }, [
                _createElementVNode("div", {
                  onClick: proceedInitBooking,
                  class: "uppercase text-size-2 py-4 px-8 text-center rounded-full bg-theme-active border-2 text-white cursor-pointer hover:bg-white hover:border-theme-active hover:text-theme-active transition ease-in-out duration-300"
                }, " Book Now ")
              ])
            ]))
          : _createCommentVNode("", true)
      ], 2)
    ], 2),
    _createVNode(ConfirmationModal, {
      show: openConfirmModal.value,
      confirmationText: lastMinuteBookText.value,
      onClosed: handleConfirmClose
    }, {
      buttons: _withCtx(() => [
        _createElementVNode("button", {
          onClick: askingConfirmed,
          type: "button",
          class: "inline-flex cursor-pointer w-full justify-center rounded-md bg-theme-active px-3 py-2 text-sm text-white shadow-sm xs:ml-3 xs:w-auto"
        }, " Request Quote "),
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (openConfirmModal.value = false)),
          type: "button",
          class: "mt-3 inline-flex w-full cursor-pointer justify-center rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 xs:mt-0 xs:w-auto"
        }, " Cancels ")
      ]),
      _: 1
    }, 8, ["show", "confirmationText"])
  ], 64))
}
}

})